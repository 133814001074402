<script setup lang="ts">
import { loadActiveEvent } from '~/api/loadEvents'

definePageMeta({
  layout: false,
})

const client = useTypedSupabaseClient()
const activeEvent = await useAsyncData(async () => await loadActiveEvent(client))
</script>

<template>
  <div>
    <MyHeader />
    <main class="flex flex-1 flex-col">
      <div class="flex flex-col bg-primary text-white w-full h-fit p-6 text-center text-2xl lg:text-6xl lg:py-6">
        <span class="font-roboto-condensed font-medium">
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          <i18n-d :value="new Date(activeEvent.data.value?.start_date || '1970')" :format="{ day: '2-digit', month: 'short' }" />
          -
          <i18n-d :value="new Date(activeEvent.data.value?.end_date || '1970')" :format="{ day: '2-digit', month: 'short', year: 'numeric' }" />
        </span>
        <span class="font-roboto-condensed font-light">
          {{ activeEvent.data.value?.name }}
        </span>
      </div>

      <div class="relative">
        <div class="absolute top-0 -z-10 h-full w-full">
          <CopyrightWrapper>
            <NuxtPicture
              src="/background_index.png"
              sizes="100vw xs:100vw sm:100vw md:100vw lg:100vw xl:100vw 2xl:100vw"
              :img-attrs="{ class: 'w-full h-full object-cover' }"
            />
            <template #copyrightNotice>
              {{ $t('copyright_credits.background_index') }}
            </template>
          </CopyrightWrapper>
        </div>
        <div class="top-0 mt-6 mb-12 lg:my-14 p-5 lg:p-9 w-4/5 lg:w-2/3 mx-auto pb-5 flex flex-col gap-6 bg-white rounded-2xl">
          <div class="text-xl lg:text-3xl lg:leading-relaxed font-medium backdrop-blur-[2px]">
            {{ $t('index.interested_hosting') }}
          </div>
          <p>{{ $t('index.we_support') }}</p>
          <div class="flex flex-col lg:flex-row items-center lg:justify-between gap-y-5">
            <LocalizedLink v-umami="'register_host_button_call_to_action'" to="/signUp/host">
              <CallToAction>
                {{ $t('index.cta.host') }}
              </CallToAction>
            </LocalizedLink>
            <LocalizedLink v-umami="'register_guest_button_call_to_action'" to="/signUp/guest">
              <CallToAction>
                {{ $t('index.cta.guest') }}
              </CallToAction>
            </LocalizedLink>
          </div>
        </div>
        <LocalizedLink to="/faq">
          <div class="flex flex-col bg-primary text-white w-full h-fit p-6 text-center text-2xl lg:text-6xl lg:py-6">
            <span class="font-roboto-condensed font-medium">
              {{ $t('index.joint_evening') }}
            </span>
          </div>
        </LocalizedLink>
      </div>

      <NewsletterSignup />

      <div class="bg-white mt-9 flex flex-col gap-y-6 mx-9">
        <LandingPageCard src="what_is_SB60.png" :copyright="$t('copyright_credits.what_is_SB60')" content-short-key="index.card.what_is_sbs.explanation.short" content-long-key="index.card.what_is_sbs.explanation.long">
          <template #headline>
            {{ $t('index.card.what_is_sbs.explanation.headline') }}
          </template>
        </LandingPageCard>

        <LandingPageCard src="what_is_bedexchange.png" :copyright="$t('copyright_credits.what_is_bedexchange')" content-short-key="index.card.what_is_bedexchange.explanation.short" content-long-key="index.card.what_is_bedexchange.explanation.long">
          <template #headline>
            {{ $t('index.card.what_is_bedexchange.explanation.headline') }}
          </template>
        </LandingPageCard>

        <LandingPageCard src="how_bedexchange_work.png" :copyright="$t('copyright_credits.how_bedexchange_work')" content-short-key="index.card.how_work_bedexchange.explanation.short" content-long-key="index.card.how_work_bedexchange.explanation.long">
          <template #headline>
            {{ $t('index.card.how_work_bedexchange.explanation.headline') }}
          </template>
        </LandingPageCard>

        <LandingPageCard src="how_can_i_be_part_of_bedexchange.png" :copyright="$t('copyright_credits.how_can_i_be_part_of_bedexchange')" content-short-key="index.card.be_part_bedexchange.explanation.short" content-long-key="index.card.be_part_bedexchange.explanation.long">
          <template #headline>
            {{ $t('index.card.be_part_bedexchange.explanation.headline') }}
          </template>
        </LandingPageCard>

        <LandingPageCard src="how_does_matching_process_work.png" :copyright="$t('copyright_credits.how_does_matching_process_work')" content-short-key="index.card.matching_process.explanation.short" content-long-key="index.card.matching_process.explanation.long">
          <template #headline>
            {{ $t('index.card.matching_process.explanation.headline') }}
          </template>
        </LandingPageCard>
      </div>

      <div class="mt-9 text-xl font-bold flex flex-col gap-7 px-5 py-7 items-center">
        <div>
          {{ $t('index.further_information') }}
        </div>
        <LocalizedLink to="/faq" class="p-7 bg-light_blue_unselected rounded w-full lg:w-fit text-center active:bg-tertiary active:text-white hover:bg-tertiary-100 hover:outline hover:outline-1 hover:outline-tertiary-300 hover:-outline-offset-1">
          {{ $t('index.faq') }}
        </LocalizedLink>
      </div>
      <div class="px-5 py-7 text-xl font-bold text-center text-white bg-primary">
        {{ $t('index.testimonials') }}
      </div>
      <div class="mt-9 grid grid-flow-col grid-rows-1 auto-cols-max gap-x-6 w-full overflow-x-scroll">
        <TestimonialCard src="testimonials_brigitte.png" name="Brigitte" role-key="index.testimonials.role.host" content-key="index.testimonials.1.content" />
        <TestimonialCard src="testimonials_u.png" name="U." role-key="index.testimonials.role.host" content-key="index.testimonials.2.content" />
        <TestimonialCard src="testimonials_aaron.png" name="Aaron" role-key="index.testimonials.role.host" content-key="index.testimonials.3.content" />
        <TestimonialCard src="testimonials_ada.png" name="Ada" role-key="index.testimonials.role.host" content-key="index.testimonials.4.content" />
      </div>
    </main>
    <MyFooter />
  </div>
</template>
